<template>
  <div>
    <!-- <this-header /> -->
    <div class="container-sm-lot pl-1 pr-1">
      <div class="top-bxt my-1">
        <img
          src="/icon/back.svg"
          alt="back"
          class="cursor-pointer ts-left"
          @click="$router.go(-1)"
        >

        <h3 class="text-primary mb-0">
          ผลรางวัลสลากรวม
        </h3>
      </div>

      <div class="bx-logo-about my-2">

        <p class="small">
          สลาก 1 งวด มี 1 ล้านชุด ชุดละ 5 ใบ (5 ล้านฉบับ) จำหน่ายชุดละ 30,000 วอน
          กำหนดรางวัลต่อชุดดังนี้
        </p>

        <div class="teble-ex mb-1">
          <img
            src="@/assets/images/newIcon/ตารางรางวัล-01.jpg"
            alt="table-reward"
            class="img-fluid"
          >
        </div>

        <p class="small text-center">
          สลาก 1 งวด มี 14,168 รางวัล เป็นเงิน 8,872,000,000 วอน (241,000,000 บาท)
        </p>
      </div>

    </div>
    <ThisFooter />
    <!-- </div> -->
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'

import ThisFooter from './components/ThisFooter.vue'

export default {
  name: 'Home',
  components: {
    // ThisHeader,
    ThisFooter,
  },
  data() {
    return {
      items: [],
      userData: JSON.parse(localStorage.getItem('userData')), // getlocalstorage
      balance: {
        balance: localStorage.getItem('balance'),
      },
      balance_withdraw: 0,
      balance_datecheck: '',
    }
  },
  created() {
    if (!this.userData) {
      this.$router.push({ name: 'home' })
    }
  },
  mounted() {
    if (this.userData) {
      this.getBalance()
    }
  },
  methods: {
    uploadProfile() {
      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      fileInput.accept = 'image/*'

      // Store a reference to the current 'this' context
      const self = this

      fileInput.addEventListener('change', event => {
        const selectedFile = event.target.files[0]
        if (selectedFile) {
          const reader = new FileReader()

          // eslint-disable-next-line func-names
          reader.onload = function () {
            const base64Image = reader.result

            // Use the 'self' reference to call the 'Upfile' function
            self.Upfile(base64Image)
          }
          reader.readAsDataURL(selectedFile)
        }
      })

      fileInput.click()
    },
    Upfile(img) {
      this.$http
        .post('users/uploadprofile/img',
          { img })
        // eslint-disable-next-line no-unused-vars
        .then(ress => {
          this.userData.profile_img = img
          localStorage.setItem('userData', JSON.stringify(this.userData))
          window.location.reload()
        })
    },
    ShowProfile() {
      if (this.userData.profile_img) {
        return this.userData.profile_img
      }
      return '/icon/user.png'
    },
    logout() {
      localStorage.removeItem('userData')
      this.$router.push({ name: 'home' })
      window.location.reload()
    },
    getBalance() {
      this.$http
        .get('balance/getbalance')
        .then(ress => {
          // console.log(ress.data)
          this.balance = ress.data
          this.balance_datecheck = moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
          localStorage.setItem('balance', JSON.stringify(ress.data.balance))
        })
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style scoped>
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}
</style>
